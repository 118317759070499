import React from 'react'
import mapboxgl from 'mapbox-gl'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import DropoffLogo from './dropoff.png'
import VehicleLogo from './car.png'

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibmphY2hvd3NraSIsImEiOiJja3l2N2lzeDMwZTlyMm90NG90Ym5jNmliIn0.pWApQO-BmKZce3e5JdRI0w'
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN
const MAPBOX_STYLE_URL = 'mapbox://styles/njachowski/ck7yfctgx0uer1ir03rqh3sgh'

const DropoffLogoHTML = () => {
  const newSpan = document.createElement('span')
  newSpan.className = 'custom-marker'
  const newImg = document.createElement('img')
  newImg.src = DropoffLogo
  newImg.style.marginBottom = '-12px'
  newSpan.appendChild(newImg)
  return newSpan
}

const VehicleLogoHTML = (numberOfStops, t) => {
  const newSpan = document.createElement('span')
  newSpan.className = 'custom-marker'
  const newImg = document.createElement('img')
  newImg.src = VehicleLogo
  const newDiv = document.createElement('div')
  newDiv.innerHTML = numberOfStops === 1
    ? `${t('delivery_status.number_of_stop.single')}`
    : `${t('delivery_status.number_of_stop.many', { numberOfStops })}`
  newDiv.style.position = 'absolute'
  newDiv.style.padding = '8px 12px'
  newDiv.style.backgroundColor = 'white'
  newDiv.style.borderRadius = '8px'
  newDiv.style.boxShadow = '0px 0px 2px rgb(0 0 0 / 25%)'
  newDiv.style.top = '-40px'
  newDiv.style.left = '-40px'
  newDiv.style.textAlign = 'center'
  newDiv.style.width = '105px'
  newDiv.style.fontSize = '14px'
  newDiv.style.fontWeight = '600'
  newDiv.style.fontFamily = 'MONTSERRAT'
  newSpan.appendChild(newImg)
  newSpan.appendChild(newDiv)
  return newSpan
}

const TripMapInternal = ({
  vehiclePosition,
  dropoffPosition,
  numberOfStops,
}) => {
  const mapContainer = React.useRef()
  const map = React.useRef()
  const { t } = useTranslation()
  React.useEffect(() => {
    if (mapContainer) {
      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAPBOX_STYLE_URL,
      })
      newMap.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
          visualizePitch: false,
        }),
      )
      new mapboxgl.Marker(DropoffLogoHTML(), { anchor: 'bottom' })
        .setLngLat([dropoffPosition.lon, dropoffPosition.lat])
        .addTo(newMap)

      if (vehiclePosition.lon !== 0 && vehiclePosition.lat !== 0) {
        new mapboxgl.Marker(VehicleLogoHTML(numberOfStops, t))
          .setLngLat([vehiclePosition.lon, vehiclePosition.lat])
          .addTo(newMap)
        if (numberOfStops === 1) {
          newMap.on('load', async () => {
            try {
              const { data } = await axios.get(
                `https://api.mapbox.com/directions/v5/mapbox/driving/${vehiclePosition.lon}%2C${vehiclePosition.lat}%3B${dropoffPosition.lon}%2C${dropoffPosition.lat}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=${MAPBOX_ACCESS_TOKEN}`,
              )
              const route = data.routes[0].geometry.coordinates
              const geojson = {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: route,
                },
              }
              if (newMap.getSource('route')) {
                newMap.getSource('route').setData(geojson)
              } else {
                newMap.addLayer({
                  id: 'route',
                  type: 'line',
                  source: {
                    type: 'geojson',
                    data: geojson,
                  },
                  layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                  },
                  paint: {
                    'line-color': '#2B303A',
                    'line-width': 5,
                  },
                })
              }
            } catch {
              // todo
              // eslint-disable-next-line no-console
              console.log('Error while loading direction')
            }
          })
        }
      }

      newMap.fitBounds(
        [
          [vehiclePosition.lon, vehiclePosition.lat],
          [dropoffPosition.lon, dropoffPosition.lat],
        ],
        { padding: 80, duration: 0 },
      )
      map.current = newMap
    }
  }, [vehiclePosition, dropoffPosition, numberOfStops, t])

  return <div style={{ width: '100%', height: '100%' }} ref={mapContainer} />
}

TripMapInternal.propTypes = {
  vehiclePosition: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }).isRequired,
  dropoffPosition: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }).isRequired,
  numberOfStops: PropTypes.number.isRequired,
}

export default TripMapInternal
