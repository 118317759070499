import styled from 'styled-components'

// Timeline Component
export const TimelineWrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
`

export const TimelineWrapperInner = styled.div`
  width: 100%;
`

export const ContainerWrapper = styled.div`
  align-items: baseline;
  display: flex;
  position: relative;
`

export const TimeWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #999999;
  line-height: 15px;
  margin-right: 8px;
  min-width: 60px;
  width: 60px;
  white-space: nowrap;
  ${ContainerWrapper}.active & {
    color: #2B303A;
  }
`

export const BodyWrapper = styled.div`
  padding-bottom: 20px;
  padding-left: 34px;
  position: relative;

  &:first-of-type {
    margin-top: -20px;
  }

  &:after,
  ${ContainerWrapper}:last-of-type &:last-of-type:after {
    border-left: 2px dotted #D8D9DB;
    bottom: 0;
    box-sizing: content-box;
    content: "";
    height: 100%;
    left: 10px;
    top: 5px;
    position: absolute;
    width: 2px;
    z-index: 1;
  }

  ${ContainerWrapper}:last-of-type &:last-of-type:after {
    box-sizing: content-box;
    display: none;
  }
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #999999;
  ${ContainerWrapper}.active & {
    color: #F9603A;
  }

  &:after {
    background-color: #d0cdc4;
    border-radius: 50%;
    box-sizing: content-box;
    content: "";
    height: 12px;
    left: 5px;
    position: absolute;
    top: 2px;
    width: 12px;
    z-index: 2;
  }
  ${ContainerWrapper}.active &:after {
    background-color: #F9603A;
  }
`

export const BodyInner = styled.div`
  font-weight: 500;
  font-size: 12px;
  min-height: 13px;
  margin: 5px 0 16px;
  color: #999999;
  &:last-child {
    margin-bottom: 0;
  }
  ${ContainerWrapper}.active & {
    color: #2B303A;
  }
`
