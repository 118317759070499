import styled from 'styled-components'
import Image from 'react-image-enlarger'

export const ExpandWrapper = styled.div`
  position: absolute;
  bottom: 9px;
  left: 35px;
  padding: 3px;
  padding-bottom: 1px;
  background: #00000047;
  text-align: center;
  border-radius: 3px;
  pointer-events: none;
`

export const StyledImage = styled(Image)`
  width: 64px;
  height: 64px;
`
