import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px;
`

export const StatusBody = styled.div`
  max-width: 600px;
  height: 300px;
  width: 100%;
  padding: 16px;
  padding-top: 40px;
`

export const IconWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
  & img {
    height: 80px;
  }
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
  & span {
    :first-child {
      color: #999999;
    }
    :last-child {
      font-weight: bold;
    }
  }
`

export const MapWrapper = styled.div`
  width: 100vw;
  background: #c9c9c9;
  height: 30vw;
  min-height: 300px;
  max-height: 500px;
`

export const ProofImageWrapper = styled.div`
  margin-top: 4px;
  position: relative;
`
