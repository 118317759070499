import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import UnavailableLogo from './unavailable.png'
import ScheduledLogo from './scheduled.png'
import OnthewayLogo from './ontheway.png'
import ArrivedLogo from './arrived.png'
import FailedLogo from './failed.png'
import {
  Container,
  StatusBody,
  IconWrapper,
  Title,
  Description,
  MapWrapper,
  ProofImageWrapper,
} from './DeliveryStatus.style'
import { getDeliveryStatus } from '../../service/deliveryStatusService'
import {
  Map,
  Note,
  Panel,
  VerticalList,
  ZoomImage,
} from '../../../../shared/component'

const BOOKING_STATE = {
  ASSIGNED: 'assigned',
  ENROUTE: 'enroute',
  COMPLETED: 'completed',
  FAILED: 'fail_to_deliver',
  REJECTED: 'rejected_by_system',
  FAILED_TO_BOARD: 'fail_to_board',
}

// fake response data - for testing
export const unavailableData = {}
export const assignedData = {
  state: BOOKING_STATE.ASSIGNED,
  scheduledDropoffTime: '2021-01-19T11:19:07.856000+00:00',
  scheduledPickupTime: '2021-01-19T11:19:07.856000+00:00',
}
export const enrouteData = {
  state: BOOKING_STATE.ENROUTE,
  scheduledDropoffTime: '2021-01-19T11:19:07.856000+00:00',
  scheduledPickupTime: '2021-01-19T11:19:07.856000+00:00',
  estimatedDropoffTime: '2021-01-19T11:19:07.856000+00:00',
  vehiclePosition: {
    lon: 103.827899,
    lat: 1.291752,
  },
  dropoffPosition: {
    lon: 103.830284,
    lat: 1.28792,
  },
  numberOfStops: 1,
  completedPickupTime: '2022-01-22T00:04:46.640458+00:00',
}
export const completedData = {
  state: BOOKING_STATE.COMPLETED,
  completedPickupTime: '2022-01-22T00:04:46.640458+00:00',
  completedDropoffTime: '2022-01-22T00:04:46.640458+00:00',
  scheduledPickupTime: '2021-01-19T11:19:07.856000+00:00',
  completedProof: {
    createdAt: '2022-01-22T00:04:46.640458+00:00',
    images: [
      'https://sg-test-11.slatic.net/other/roc/7ae3dabf47b10136d88e7a6b2c1efac1.png',
      'https://symbols.vn/wp-content/uploads/2021/11/Icon-address-don-gian-Png.jpg',
    ],
  },
  failedData: [],
}
export const failedOrder = {
  state: BOOKING_STATE.FAILED,
  failedTime: '2022-01-22T00:04:46.640458+00:00',
  completedPickupTime: '2022-01-22T00:04:46.640458+00:00',
}

// end

const getTimeRange = (scheduled, estimated, t) => {
  if (!scheduled && !estimated) {
    return t('label.unavailable')
  }
  if (!scheduled) {
    return estimated.format('h:mm A')
  }
  if (!estimated) {
    return scheduled.format('h:mm A')
  }
  return scheduled.isBefore(estimated)
    ? `${scheduled.format('h:mm A')} - ${estimated.format('h:mm A')}`
    : `${estimated.format('h:mm A')} - ${scheduled.format('h:mm A')}`
}

const DeliveryStatus = ({ code }) => {
  const [data, setData] = React.useState(unavailableData)
  const [loadingData, setLoadingData] = React.useState(true)
  const { t, ready: tReady } = useTranslation()
  const orderUid = code.split('_')[1] || code
  React.useEffect(() => {
    const getOrderData = async () => {
      const orderData = await getDeliveryStatus(code).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        setData(unavailableData)
      })
      if (orderData) {
        setData(orderData)
      }
      setLoadingData(false)
    }
    getOrderData()
  }, [code])
  if (loadingData || !tReady) return null
  const {
    state,
    scheduledDropoffTime,
    scheduledPickupTime,
    estimatedDropoffTime,
    vehiclePosition,
    dropoffPosition,
    numberOfStops,
    completedPickupTime,
    completedDropoffTime,
    completedProof,
    failedTime,
    id,
    dropoffLocation,
    failedData,
  } = data
  return (
    <Container>
      {(state === undefined
      || state === BOOKING_STATE.REJECTED
      || state === BOOKING_STATE.FAILED_TO_BOARD) && (
        <StatusBody>
          <IconWrapper>
            <img src={UnavailableLogo} alt="unavailable" />
          </IconWrapper>
          <Title>
            <span>{t('delivery_status.unavailable.title')}</span>
          </Title>
          <Description>
            <span>{`${t('label.estimated_arrival')} `}</span>
            <span>{t('label.unavailable')}</span>
          </Description>
          <Note message={t('delivery_status.unavailable.note')} />
        </StatusBody>
      )}
      {state === BOOKING_STATE.ASSIGNED && (
        <StatusBody>
          <IconWrapper>
            <img src={ScheduledLogo} alt="scheduled" />
          </IconWrapper>
          <Title>
            <span>{t('delivery_status.assigned.title')}</span>
          </Title>
          <Description>
            <span>{`${t('label.estimated_arrival')} `}</span>
            <span>
              {scheduledDropoffTime
                ? moment(scheduledDropoffTime).format('ddd, DD MMM')
                : 'Unavailable'}
            </span>
          </Description>
          <Panel title={`${t('label.tracking_order')} ${id || orderUid}`}>
            <VerticalList
              data={
                scheduledPickupTime
                  ? [
                    {
                      time: scheduledPickupTime,
                      title: t('delivery_status.nodes.prepare'),
                      more: dropoffLocation ? `${t('label.to')}: ${dropoffLocation}` : '',
                      active: true,
                    },
                  ]
                  : []
              }
            />
          </Panel>
        </StatusBody>
      )}
      {state === BOOKING_STATE.ENROUTE && (
        <>
          <MapWrapper>
            <Map
              vehiclePosition={vehiclePosition}
              dropoffPosition={dropoffPosition}
              numberOfStops={numberOfStops || 1}
            />
          </MapWrapper>
          <StatusBody>
            <IconWrapper>
              <img src={OnthewayLogo} alt="ontheway" />
            </IconWrapper>
            <Title>
              <span>{t('delivery_status.enroute.title')}</span>
            </Title>
            <Description>
              <span>{`${t('label.estimated_arrival')} `}</span>
              <span>
                {getTimeRange(
                  undefined,
                  moment(estimatedDropoffTime),
                  t,
                )}
              </span>
            </Description>
            <Panel title={`${t('label.tracking_order')} ${id || orderUid}`}>
              <VerticalList
                data={
                  completedPickupTime
                    ? [
                      {
                        time: completedPickupTime,
                        title: t('delivery_status.nodes.ontheway'),
                        more: '',
                        active: true,
                      },
                      {
                        time: scheduledPickupTime,
                        title: t('delivery_status.nodes.prepare'),
                        more: dropoffLocation ? `${t('label.to')}: ${dropoffLocation}` : '',
                        active: false,
                      },
                    ]
                    : []
                }
              />
            </Panel>
          </StatusBody>
        </>
      )}
      {state === BOOKING_STATE.COMPLETED && (
        <StatusBody>
          <IconWrapper>
            <img src={ArrivedLogo} alt="arrived" />
          </IconWrapper>
          <Title>
            <span>{t('delivery_status.completed.title')}</span>
          </Title>
          <Description>
            <span>{`${t('label.arrived_at')} `}</span>
            <span>
              {completedDropoffTime
                ? moment(completedDropoffTime).format('DD MMM h:mm A')
                : 'Unavailable'}
            </span>
          </Description>
          <Panel title={`${t('label.tracking_order')} ${id || orderUid}`}>
            <VerticalList
              data={[
                {
                  time: completedDropoffTime,
                  title: t('delivery_status.nodes.completed'),
                  more: (
                    <div>
                      {completedProof.images.map((image) => (
                        <ProofImageWrapper key={image}>
                          <ZoomImage
                            src={image}
                            alt="proof"
                            style={{
                              height: 64, width: 64, objectFit: 'cover', borderRadius: 8,
                            }}
                          />
                        </ProofImageWrapper>
                      ))}
                    </div>
                  ),
                  active: true,
                },
                ...failedData.map((i) => ({
                  time: i.createdAt,
                  title: t('delivery_status.nodes.failed'),
                  more: i.comment || '',
                  active: false,
                })),
                {
                  time: completedPickupTime,
                  title: t('delivery_status.nodes.ontheway'),
                  more: '',
                  active: false,
                },
                {
                  time: scheduledPickupTime,
                  title: t('delivery_status.nodes.prepare'),
                  more: dropoffLocation ? `${t('label.to')}: ${dropoffLocation}` : '',
                  active: false,
                },
              ]}
            />
          </Panel>
        </StatusBody>
      )}
      {state === BOOKING_STATE.FAILED && (
        <StatusBody>
          <IconWrapper>
            <img src={FailedLogo} alt="failed" />
          </IconWrapper>
          <Title>
            <span>{t('delivery_status.failed.title')}</span>
          </Title>
          <Description>
            <span>{`${t('label.attempted_at')} `}</span>
            <span>
              {failedTime
                ? moment(failedTime).format('DD MMM h:mm A')
                : t('label.unavailable')}
            </span>
          </Description>
          <Note message={t('delivery_status.failed.note')} />
          <Panel title={`${t('label.tracking_order')} ${id || orderUid}`}>
            <VerticalList
              data={[
                ...failedData.map((i, index) => ({
                  time: i.createdAt,
                  title: t('delivery_status.nodes.failed'),
                  more: i.comment || '',
                  active: index === 0,
                })),
                {
                  time: completedPickupTime,
                  title: t('delivery_status.nodes.ontheway'),
                  more: '',
                  active: false,
                },
                {
                  time: scheduledPickupTime,
                  title: t('delivery_status.nodes.prepare'),
                  more: dropoffLocation ? `${t('label.to')}: ${dropoffLocation}` : '',
                  active: false,
                },
              ]}
            />
          </Panel>
        </StatusBody>
      )}
    </Container>
  )
}
DeliveryStatus.propTypes = {
  code: PropTypes.string.isRequired,
}

export default DeliveryStatus
