import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Header,
  Body,
} from './Panel.style'

const Panel = ({ title, children }) => (
  <Container>
    <Header>{title}</Header>
    <Body>
      {children}
    </Body>
  </Container>
)

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Panel
