import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-montserrat'
import 'mapbox-gl/dist/mapbox-gl.css'

import App from './layout/App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
