import axios from 'axios'
import get from 'lodash/get'

import config from '../../../config/config'

export const getDeliveryStatus = async (code) => {
  const { data: deliveryStatus } = await axios.get(
    `${config.SERVER_URL}/microservices/bookingprogress?track=${code}`,
  )
  return {
    id: deliveryStatus.id,
    state: deliveryStatus.booking_state,
    pickupPosition: {
      lat: get(deliveryStatus, 'nodes.pickup.lat'),
      lon: get(deliveryStatus, 'nodes.pickup.lon'),
    },
    dropoffPosition: {
      lat: get(deliveryStatus, 'nodes.dropoff.lat'),
      lon: get(deliveryStatus, 'nodes.dropoff.lon'),
    },
    vehiclePosition: {
      lat: get(deliveryStatus, 'vehicle.lat'),
      lon: get(deliveryStatus, 'vehicle.lon'),
    },
    estimatedPickupTime: get(
      deliveryStatus,
      'nodes.pickup.estimated_scheduled_ts',
    ),
    estimatedDropoffTime: get(
      deliveryStatus,
      'nodes.dropoff.estimated_scheduled_ts',
    ),
    scheduledPickupTime: ((get(deliveryStatus, 'status_history') || [])
      .find((item) => item.type === 'ASSIGNMENT') || {}).timestamp,
    scheduledDropoffTime: get(deliveryStatus, 'nodes.dropoff.scheduled_ts'),
    numberOfStops: get(deliveryStatus, 'number_of_locations_to_dropoff'),
    completedPickupTime: get(deliveryStatus, 'pickup_completed_at_ts'),
    completedDropoffTime: get(deliveryStatus, 'dropoff_completed_at_ts'),
    completedProof: [
      (get(deliveryStatus, 'dropoff_node_actions') || []).find(
        (item) => item.action_type === 'proof_of_delivery',
      ) || {},
    ].map((item) => ({
      images: item.image_urls || [],
      createdAt: item.created_at,
    }))[0],
    failedData: (get(deliveryStatus, 'failure_to_deliver_data') || [])
      .filter((item) => item.action_type === 'failure_of_delivery')
      .map((item) => ({
        createdAt: item.created_at,
        comment: get(item, 'data.comment'),
      })),
    failedTime: get(deliveryStatus, 'failed_to_deliver_at_ts'),
    dropoffLocation: deliveryStatus.dropoff_location_name,
  }
}
