import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'ja'],
    load: 'languageOnly',
    fallbackLng: 'en',
    lowerCaseLng: true,
    debug: false,
    returnEmptyString: false,
    ns: ['translation'],
    backend: {
      loadPath: `/locales/{{ns}}.{{lng}}.json?q=${Math.random()}`, // Clear cache
    },
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lang',
      cookieMinutes: 60 * 24 * 7, // 7 days
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
