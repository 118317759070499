import React from 'react'
import { I18nextProvider } from 'react-i18next'
import moment from 'moment'
import jaLocale from 'moment/locale/ja'
import enLocale from 'moment/locale/en-gb'

import './App.css'
import { AppBody } from './App.style'
import DeliveryStatus from './components/DeliveryStatus'
import i18n from '../../i18n'

const TRACKING_PATH = '/'

const App = () => {
  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')
  const lang = params.get('lang')
  if (lang) {
    i18n.changeLanguage(lang.substring(0, 2))
    if (lang.substring(0, 2) === 'ja') {
      moment.locale('ja', jaLocale)
    } else {
      moment.locale('en', enLocale)
    }
  }
  return (
    <I18nextProvider i18n={i18n}>
      <AppBody>
        {window.location.pathname === TRACKING_PATH && code && <DeliveryStatus code={code} />}
      </AppBody>
    </I18nextProvider>
  )
}

export default App
