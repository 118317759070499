import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Bell } from './bell.svg'
import { Container, IconWrapper } from './Note.style'

const Note = ({ message }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <IconWrapper>
        <Bell />
      </IconWrapper>
      <span>
        <b>
          {`${t('label.note')}: `}
        </b>
        <i>{message}</i>
      </span>
    </Container>
  )
}

Note.propTypes = {
  message: PropTypes.string.isRequired,
}

export default Note
