import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
`
export const Header = styled.div`
  background: #FAFAFA;
  padding: 16px;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  border-bottom: 1px solid #EBEBEB;
`

export const Body = styled.div`
  padding: 16px;
`
