import styled from 'styled-components'

export const Container = styled.div`
  background: #FAFAFA;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 24px;
`
export const IconWrapper = styled.div`
  margin-right: 11px;
`
