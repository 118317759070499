import React from 'react'
import ExpandLogo from './expand.png'

import { ExpandWrapper, StyledImage } from './ZoomImage.style'

function ZoomImage(props) {
  const [zoomed, setZoomed] = React.useState(false)

  return (
    <>
      <StyledImage
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        zoomed={zoomed}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
      <ExpandWrapper>
        <img src={ExpandLogo} alt="expand" />
      </ExpandWrapper>
    </>
  )
}

export default ZoomImage
