import React from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import moment from 'moment'

import {
  ContainerWrapper,
  TimeWrapper,
  BodyWrapper,
  BodyInner,
  TimelineWrapper,
  TimelineWrapperInner,
  Title,
} from './VerticalList.style'

const VerticalList = ({ data }) => (
  <>
    <TimelineWrapper>
      <TimelineWrapperInner>
        {data.map((item, index) => (
          <ContainerWrapper key={index} className={item.active ? 'active' : ''}>
            <TimeWrapper dangerouslySetInnerHTML={{ __html: item.time ? moment(item.time).format('DD MMM <br\\>hh:mm A') : '' }} />
            <BodyWrapper>
              <Title>{item.title}</Title>
              <BodyInner>{item.more}</BodyInner>
            </BodyWrapper>
          </ContainerWrapper>
        ))}
      </TimelineWrapperInner>
    </TimelineWrapper>
  </>
)

VerticalList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    time: oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    more: PropTypes.node,
    active: PropTypes.bool,
  })).isRequired,
}

export default VerticalList
