import { devConfig } from './config.dev'
import { stagingConfig } from './config.stage'
import { prodConfig } from './config.prod'

// eslint-disable-next-line import/no-mutable-exports
let config
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    config = devConfig
    break
  case 'stage':
    config = stagingConfig
    break
  case 'prod':
    config = prodConfig
    break
  default:
    config = {
      SERVER_URL: 'https://sgerp-stage.d.gcdev.swatrider.com/api/v2',
    }
}

export default config
